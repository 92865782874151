import React, { useState, useContext, useEffect, useCallback, useReducer } from 'react';
import axios from 'axios';

import { Box, Step, Stepper, StepLabel, Modal, Button, Paper, Backdrop, CircularProgress } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../App';
import { EntityContext } from '../../../context/EntityContext';

import CCInfo from './PaymentTabs/CCInfo';
import Disclosure from './PaymentTabs/Disclosure';
import ContactInfo from './PaymentTabs/ContactInfo';
import PaymentReview from './PaymentTabs/PaymentReview';
import DeclinedModal from './DeclinedModal';

const steps = ['Contact Information', 'Credit Card Information', 'Payment Disclosure', 'Review Payment'];

const PaymentInfo = (props) => {
    const {
        paymentsArray,
        paymentAmount,
        totalPayments,
        fee,
        declinedModal,
        closeDecline,
        activeStep,
        handleClose,
        handleBack,
        handleNext,
        decline,
        defendantName,
        ts,
        caseId,
        isSmallScreen
    } = props;

    const { entity } = useContext(EntityContext);

    const [displayPhoneNumber, setDisplayPhoneNumber] = useState('');
    const [displayCreditCardNumber, setDisplayCreditCardNumber] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [validForm, setValidForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingT, setLoadingT] = useState(false);

    const initialState = {
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        creditCardNumber: '',
        month: '',
        year: '',
        securityCode: '',
        billingAddress: '',
        city: '',
        state: '',
        zipCode: '',
    };
    //const initialState = {
    //    email: '',
    //    phoneNumber: '',
    //    firstName: 'Brock',
    //    lastName: 'xzample',
    //    creditCardNumber: '4539791001730106',
    //    month: '01',
    //    year: '2027',
    //    securityCode: '222',
    //    billingAddress: '34 Broadway',
    //    city: 'New York',
    //    state: 'NY',
    //    zipCode: '10004-1608',
    //};

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'email':
                if (!value) {
                    error = 'Email Required'
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    error = 'Enter a valid email'
                }
                break;
            case 'phoneNumber':
                if (!!value && value.length !== 10) {
                    error = 'Phone number must be 10 digits'
                }
                break;
            case 'firstName':
                if (!value) {
                    error = 'First name is required'
                }
                break;
            case 'lastName':
                if (!value) {
                    error = 'Last name is required'
                }
                break;
            case 'creditCardNumber':
                if (!value) {
                    error = 'Credit Card Number is required'
                } else if (value < 16 || value > 19) {
                    error = 'Enter valid credit card number'
                }
                break;
            case 'month':
                if (!value) {
                    error = 'Expiration month is required'
                }
                break;
            case 'year':
                if (!value) {
                    error = 'Expiration year is required'
                } else if (parseInt(value, 10) < new Date().getFullYear()) {
                    error = 'Can not use expired card'
                }
                break;
            case 'securityCode':
                if (!value) {
                    error = 'CVV/CVC is required'
                } else if (value.length < 3 || value.length > 4) {
                    error = 'Enter valid CVV/CVC'
                }
                break;
            case 'billingAddress':
                if (!value) {
                    error = 'Billing address is required'
                }
                break;
            case 'city':
                if (!value) {
                    error = 'City is required'
                }
                break;
            case 'state':
                if (!value) {
                    error = 'State is required'
                }
                break;
            case 'zipCode':
                if (!value) {
                    error = 'ZipCode is required'
                }
                break;
            default:
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    useEffect(() => {
        const fullName = `${state?.firstName} ${state?.lastName}`;
        setDisplayName(fullName);
    }, [state?.firstName, state?.lastName]);

    // function to set validForm state to it's correct values based on the terms
    const nextButtonCheck = useCallback(() => {
        if (activeStep === 0) {
            if (state?.email === '' || state?.errors.email) {
                setValidForm(false);
            } else {
                setValidForm(true);
            }
        } else if (activeStep === 1) {
            // boolean variables to check and see if all of the form's values/errors are empty strings
            const isFormEmpty = Object.keys(state).filter((key) => key !== 'phoneNumber').every((key) => state[key] !== '');
            const isErrorsEmpty = Object.values(state?.errors).some((value) => value !== null);

            setValidForm(isFormEmpty || isErrorsEmpty);
        }
    }, [activeStep, state]);

    // useEffect that runs the nextButtonCheck function
    useEffect(() => {
        nextButtonCheck();
    }, [nextButtonCheck]);

    // array of the components that render in each tab
    const stepContent = [
        <ContactInfo
            state={state}
            handleFieldChange={handleFieldChange}
            handleBlur={handleBlur}
            displayPhoneNumber={displayPhoneNumber}
            setDisplayPhoneNumber={setDisplayPhoneNumber}
            ts={ts}
            isSmallScreen={isSmallScreen}
        />,
        <CCInfo
            state={state}
            handleFieldChange={handleFieldChange}
            handleBlur={handleBlur}
            displayCreditCardNumber={displayCreditCardNumber}
            setDisplayCreditCardNumber={setDisplayCreditCardNumber}
            ts={ts}
            isSmallScreen={isSmallScreen}
        />,
        <Disclosure />,
        <PaymentReview
            paymentsArray={paymentsArray}
            name={state.name}
            email={state.email}
            displayPhoneNumber={displayPhoneNumber}
            creditCardNumber={state?.creditCardNumber}
            month={state?.month}
            year={state?.year}
            paymentAmount={paymentAmount}
            totalPayments={totalPayments}
            fee={fee}
            displayName={displayName}
            defendantName={defendantName}
            isSmallScreen={isSmallScreen}
        />
    ];

    const createPayment = (payment, transactionId) => {
        const newPayment = {
            FkCaseId: caseId,
            FkViolationId: payment.pkViolationId,
            PaymentRef: payment.currentStatute,
            PaymentAmount: parseFloat(payment.payment),
            ShiftDate: new Date(),
            PaymentType: 'CREDIT CARD',
            BatchNumber: '1234',
            PayToName: `${state?.firstName} ${state?.lastName}`,
            PayToAddress1: state?.billingAddress,
            PayToCity: state?.city,
            PayToState: state?.state,
            PayToZip: state?.zipCode,
            ConvenienceFee: fee,
            AuthorizationNumber: transactionId
        };

        axios.post(`${apiRoute}/api/ffpaymenttbls/${entity.id}`, newPayment)
            .then((res) => {})
            .catch((err) => console.log(err))
    };

    const handleSubmit = () => {
        setLoading(true);
        const creditCardNumber = state?.creditCardNumber?.replace(/\s/g, '');
        const ccPaymentData = {
            CardHolderEmail: state?.email,
            CardHolderPhone: state?.phoneNumber,
            CardHolderName: `${state?.firstName} ${state?.lastName}`,
            CardHolderAddressLine1: state?.billingAddress,
            CardHolderCity: state?.city,
            CardHolderState: state?.state,
            CardHolderZip: state?.zipCode,
            PaymentAmount: paymentAmount,
            ConvenienceFee: fee
        };

        axios.post(`${apiRoute}/api/CcPayments/${creditCardNumber}/${state?.month}/${state?.year}/${state?.securityCode}/${state?.firstName}/${state?.lastName}/${entity.id}`, ccPaymentData)
            .then((res) => {
                if (res.data.response !== '1') {
                    // The POST request/payment failed
                    console.error('Failed to create payment', res.data);
                    decline();
                } else {
                    // The POST request/payment was successful
                    paymentsArray.map((payment) => {
                        return createPayment(payment, res.data.transactionId)
                    })
                    handleNext();
                }
            })
            .catch((err) => {
                console.error(err);
                //toast.error(`Error ${err.response.status} while trying to submit payment`, {
                //    position: "top-right",
                //    autoClose: 2000,
                //    hideProgressBar: true,
                //    closeOnClick: true,
                //    pauseOnHover: true,
                //    draggable: true,
                //    progress: undefined,
                //    theme: "colored",
                //});
                decline();
            })
            .finally(() => setLoading(false))
    };

    const printReceipt = () => {
        const cardholderName = `${state?.firstName} ${state?.lastName}`;
        // CC number where only the last 4 digits are visable
        const CCLast4 = state?.creditCardNumber.replace(/\d{4}(?=\s\d{4})/g, '****');

        // map through the payments Array pulling currentStatute, description, and payment
        const ViolationDtl = paymentsArray.map((payment) => {
            let details = { currentStatute: payment.currentStatute, description: payment.description, payment: payment.payment };
            return details;
        });
        const ViolationDtlJSON = JSON.stringify(ViolationDtl);

        // parameters
        const searchParams = new URLSearchParams();
        if (cardholderName) searchParams.set('CardholderName', cardholderName);
        if (CCLast4) searchParams.set('CCLast4', CCLast4);
        if (state?.month) searchParams.set('CCexpMonth', state?.month)
        if (state?.year) searchParams.set('CCexpYear', state?.year)
        if (state?.email) searchParams.set('Email', state?.email);
        if (paymentAmount) searchParams.set('Total', paymentAmount);
        if (fee) searchParams.set('CCFeeAmount', fee);
        if (totalPayments) searchParams.set('SubTotal', totalPayments);
        if (ViolationDtlJSON) searchParams.set('ViolationDtlJSON', ViolationDtlJSON);
        if (defendantName) searchParams.set('DefendantName', defendantName);
        if (entity.entityName) searchParams.set('sherOffice', entity.entityName);

        axios.get(`${apiRoute}/api/PaymentReceipt?${searchParams.toString()}`, { responseType: 'arraybuffer' })
            .then(({ data }) => {
                const arrayBufferView = new Uint8Array(data);
                const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
            }).catch((e) => console.log(e));
    };

    const emailReceipt = () => {
        setLoadingT(true);
        const cardholderName = `${state?.firstName} ${state?.lastName}`;
        // CC number where only the last 4 digits are visable
        const CCLast4 = state?.creditCardNumber.replace(/\d{4}(?=\s\d{4})/g, '****');

        // map through the payments Array pulling currentStatute, description, and payment
        const ViolationDtl = paymentsArray.map((payment) => {
            let details = { currentStatute: payment.currentStatute, description: payment.description, payment: payment.payment };
            return details;
        });
        const ViolationDtlJSON = JSON.stringify(ViolationDtl);

        // parameters
        const searchParams = new URLSearchParams();
        if (cardholderName) searchParams.set('CardholderName', cardholderName);
        if (CCLast4) searchParams.set('CCLast4', CCLast4);
        if (state?.month) searchParams.set('CCexpMonth', state?.month)
        if (state?.year) searchParams.set('CCexpYear', state?.year)
        if (state?.email) searchParams.set('Email', state?.email);
        if (paymentAmount) searchParams.set('Total', paymentAmount);
        if (fee) searchParams.set('CCFeeAmount', fee);
        if (totalPayments) searchParams.set('SubTotal', totalPayments);
        if (ViolationDtlJSON) searchParams.set('ViolationDtlJSON', ViolationDtlJSON);
        if (defendantName) searchParams.set('DefendantName', defendantName);
        if (entity.entityName) searchParams.set('sherOffice', entity.entityName);

        axios.post(`${apiRoute}/api/PaymentReceipt/emailCheck?${searchParams.toString()}`, false, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then(() => {
                toast.success(`A copy of the receipt was emailed to ${state?.email}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .catch((err) => {
                toast.error(`Error ${err.response.status} while trying to email receipt`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => setLoadingT(false));
    };

    return (
        <Paper elevation={10} sx={{ height: isSmallScreen ? '100%' : 'auto', overflowY: 'auto' }} display="flex" flexDirection={isSmallScreen ? 'column' : 'column'} >
            <Paper sx={{ textAlign: 'center', backgroundColor: 'steelblue', color: 'white', p: '1vh', mb: '2vh' }} elevation={10}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '95%', m: '0 auto' }}>
                    {
                        isSmallScreen ?
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <div onClick={handleClose}>
                                        <h2 style={{ margin: 0, cursor: 'pointer' }}>
                                            <ArrowBackIcon sx={{ fontSize: '2rem' }} />
                                        </h2>
                                        Exit
                                    </div>
                                </Box>
                                <h3 style={{ flex: 1, margin: 0 }}>Payment Info</h3>
                            </>
                            :
                            <>
                                <h3 style={{ flex: 1, margin: 0 }}>Payment Info</h3>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div onClick={handleClose}>
                                        <h2 style={{ margin: 0, cursor: 'pointer' }}>
                                            <CloseIcon sx={{ fontSize: '2rem' }} />
                                        </h2>
                                    </div>
                                </Box>
                            </>
                    }
                </Box>
            </Paper>
            <Stepper activeStep={activeStep} orientation={isSmallScreen ? "vertical" : "horizontal"} sx={{ m: '1vh 7vw', p: 'auto' }}>
                {steps.map((label) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps} sx={{ '& .MuiStepLabel-label': { fontSize: isSmallScreen ? '1rem' : null } }}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <hr />
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Box sx={{ mt: 2, mb: 1, px: 1 }}>
                        <h4 style={{ textAlign: 'center', marginBotton: '1vh' }}>Payment Accepted</h4>
                        <p>Thank you for your payment. Should you have any billing questions or concerns regarding this payment and/or the amount debited from your account, please contact support@interactiveus.com</p>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', p: 1 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={printReceipt} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>Print Receipt</Button>
                        <Button disabled={loadingT} onClick={emailReceipt} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>
                            {
                                loadingT
                                    ? <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}><CircularProgress size={20} /></Box>
                                    : 'Email Receipt'
                            }
                        </Button>
                        <Button onClick={handleBack} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ mt: 2, mb: 1 }}>{stepContent[activeStep]}</Box>
                        <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', px: 2, py: .5, gap: isSmallScreen ? '.5rem' : null, mb: '1vh' }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ backgroundColor: 'gray', width: isSmallScreen ? '100%' : null, height: isSmallScreen ? '7.5vh' : null }}
                                variant="contained"
                                startIcon={<ArrowBackIcon />}
                            >
                                Back

                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {activeStep === steps.length - 1
                                ?
                                <Box>
                                    <Button
                                        onClick={handleSubmit}
                                        variant='contained'
                                        sx={{ backgroundColor: 'green', color: 'white', width: isSmallScreen ? '100%' : null, height: isSmallScreen ? '7.5vh' : null }}

                                    >
                                        <strong>Confirm Payment</strong>
                                    </Button>
                                </Box>
                                :
                                <Button
                                    onClick={handleNext}
                                    variant='contained'
                                    sx={{ backgroundColor: 'steelblue', color: 'white', width: isSmallScreen ? '100%' : null, height: isSmallScreen ? '7.5vh' : null }}
                                    disabled={!validForm}
                                >
                                    Next
                                </Button>
                            }
                        </Box>
                    </form>
                    {
                        declinedModal && (
                            <Modal open={declinedModal} onClose={closeDecline} sx={{ height: '25%', width: "25%", margin: "10vh auto", backgroundColor: 'red', borderRadius: '2%' }}>
                                <>
                                    <DeclinedModal closeDecline={closeDecline} />
                                </>
                            </Modal>
                        )
                    }
                </React.Fragment>
            )}
            {loading && (
                <Backdrop open sx={{ zIndex: 1 }}>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <p>
                            <CircularProgress />
                        </p>
                    </div>
                </Backdrop>
            )}
        </Paper>
    );
};

export default PaymentInfo;