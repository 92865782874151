import React, { useContext, useEffect, useState } from 'react';

import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    LinearProgress,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { useTheme } from '@mui/material/styles';
import { ThemeModeContext } from '../../App';


const SearchResults = (props) => {
    const { searchResults, loadingSearch, defendantName, ts, caseId, isSmallScreen, paymentsArray, handleOpen, handleClose, setPaymentAmount, payments, setFee, fee, setPayments, handleSubmit, entity, handlePaymentChange, paymentData, paymentValue, setPaymentsSubmitted } = props

    const { mode } = useContext(ThemeModeContext);
    const theme = useTheme();

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    // table/data-grid columns
    const columns = [
        {
            field: 'currentStatute',
            headerName: 'Statute',
            width: 120,
            renderHeader: (params) => isSmallScreen ? null : params.colDef.headerName,
        },
        {
            field: 'description',
            headerName: "Charges",
            width: isSmallScreen ? 180 : 300,
            headerClassName: 'hideRightSeparator',
            sortable: !isSmallScreen
            //flex: isSmallScreen ? 1 : null
        },
        {
            field: 'totalFines',
            headerName: 'Fines',
            width: 120,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
            renderHeader: (params) => isSmallScreen ? null : params.colDef.headerName,
        },
        {
            field: 'totalFees',
            headerName: 'Fees',
            width: 120,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
            renderHeader: (params) => isSmallScreen ? null : params.colDef.headerName,
        },
        {
            field: 'totalBalance',
            headerName: 'Total Due',
            width: isSmallScreen ? 80 : 120,
            align: 'right',
            headerAlign: 'right',
            valueFormatter: (params) => `$${parseFloat(params.value).toFixed(2)}`,
            renderHeader: (params) => isSmallScreen ? null : params.colDef.headerName,
            headerClassName: 'hideRightSeparator',
            sortable: !isSmallScreen
        },
        {
            field: 'payment',
            headerName: 'Payment Today',
            width: isSmallScreen ? 'auto' : 150,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => {
                const row = params?.row;
                const paymentValue = (payments && payments[row?.id]) || '';

                return (
                    <TextField
                        fullWidth
                        sx={ts}
                        id={`payment-${row?.id}`}
                        placeholder="####.##"
                        value={paymentValue}
                        onChange={(e) => handlePaymentChange(e, row)}
                    />
                );
            },
            renderHeader: (params) => isSmallScreen ? null : params.colDef.headerName,
            headerClassName: 'hideRightSeparator',
            sortable: !isSmallScreen
        },
    ];

    const customLocaleText = `Online Payments are not allowed on this case. Payments can only be made in person at\n${entity?.entityName} located at: ${entity?.streetNumber} ${entity?.streetName}, ${entity?.city}, ${entity?.state} ${entity?.zip}.\nPlease contact the Sheriff's office at ${entity?.phone} with any questions or concerns regarding this matter.`

    const onlinePaymentsNotAllowedMessage = (
        <>
            {customLocaleText.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </>
    );

    const disablePaymentsValidation = searchResults?.some(a => a.isDataMisMatch || a.isCasePaymentLocked || a.caseStatus.toUpperCase() === 'HOLD' || a.issuedChargeBack || a.isCreditCardPaymentsLocked)

    const localeText = {
        noRowsLabel: disablePaymentsValidation ? onlinePaymentsNotAllowedMessage : "No rows available",
    };

    return (
        <Card sx={{ width: isSmallScreen ? '99%' : '55%', m: '.5vh auto' }} elevation={5}>
            <CardHeader title="Violations With Outstanding Balances" />
            <CardContent sx={{ margin: isSmallScreen ? '0 0' : null, p: '0', pl: '.2' }}>
                {isSmallScreen ? <Box sx={{ height: "35vh", overFlow: "auto", width: 'auto', mx: '1vw' }}>
                    <DataGrid
                        rows={disablePaymentsValidation ? [] : searchResults}
                        columns={columns.filter(col => col.field === 'payment' || col.field === 'totalBalance' || col.field === 'description').map(c => c)}
                        loading={loadingSearch}
                        slots={{ loadingOverlay: LinearProgress }}
                        localeText={localeText}
                        disableRowSelectionOnClick
                        hideHeader={columns.find(col => col.field !== 'description')}
                        disableColumnMenu={true}  // Disable column menu for all columns
                        disableColumnResize={true}
                        disableSort={true}
                        sx={{
                            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                marginTop: "1em",
                                marginBotton: "1em"
                            },
                            "& .MuiDataGrid-overlay": {
                                fontSize: "1.3rem"
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                //borderBottom: 'none',
                                borderLeft: 'none',// Remove the bottom border of the column headers
                                borderRight: 'none',// Remove the bottom border of the column headers
                            },
                            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
                                borderRight: 'none',  // No vertical borders between columns
                            },
                            '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
                                display: 'none',
                            }, '& .MuiDataGrid-cell--focus': {
                                outline: 'none', // Remove focus outline from cells
                            },
                            '& .MuiDataGrid-columnHeader--focus': {
                                outline: 'none', // Remove focus outline from column headers
                            },
                            '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                                ':focus': {
                                    outline: 'none', // Ensure no focus outline is shown on focus
                                },
                            },
                        }}
                    />
                </Box> : <Box sx={{ height: "35vh", overFlow: "auto" }}>
                    <DataGrid
                        rows={disablePaymentsValidation ? [] : searchResults}
                        columns={columns}
                        loading={loadingSearch}
                        slots={{ loadingOverlay: LinearProgress }}
                        localeText={localeText}
                        disableRowSelectionOnClick
                        sx={{
                            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                marginTop: "1em",
                                marginBotton: "1em"
                            },
                            "& .MuiDataGrid-overlay": {
                                fontSize: "1.3rem"
                            }, '& .MuiDataGrid-cell--focus': {
                                outline: 'none', // Remove focus outline from cells
                            },
                            '& .MuiDataGrid-columnHeader--focus': {
                                outline: 'none', // Remove focus outline from column headers
                            },
                            '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                                ':focus': {
                                    outline: 'none', // Ensure no focus outline is shown on focus
                                },
                            },// First row padding
                            '& .MuiDataGrid-row:nth-of-type(1) .MuiDataGrid-cell': {
                                paddingLeft: 5,
                            },
                            // Last row padding
                            '& .MuiDataGrid-row:last-of-type .MuiDataGrid-cell': {
                                paddingLeft: 5,
                            },
                            // First column padding
                            '& .MuiDataGrid-cell:first-of-type': {
                                paddingLeft: 2,
                            },
                            // Last column padding
                            '& .MuiDataGrid-cell:last-of-type': {
                                paddingRight: 2,
                            },
                            // First column header padding
                            '& .MuiDataGrid-columnHeader:first-of-type': {
                                paddingLeft: 5,
                            },
                            // Last column header padding
                            '& .MuiDataGrid-columnHeader:last-of-type': {
                                paddingRight: 2,
                            },
                        }}
                    />
                </Box>}
            </CardContent>
            <CardActions sx={{ display: 'flex', justifyContent: 'end', pb: '1vh' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                    disabled={disablePaymentsValidation || searchResults?.length < 1 || !paymentsArray?.length || (paymentData && !Object.values(paymentData)?.some(payment => payment?.payment > '0.00'))}
                    sx={{ backgroundColor: 'steelblue', color: 'white', height: isSmallScreen ? '7.5vh' : null, width: isSmallScreen ? '100%' : null }}
                >
                    <strong>Submit Payments</strong>
                </Button>
            </CardActions>

        </Card>
    )
}

export default SearchResults;