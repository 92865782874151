import React from 'react';

import { Paper, Box } from '@mui/material';

const Disclosure = () => {
    return (
        <Box sx={{ p: '1vh', pt: 0 }}>
            <Paper sx={{ p: '1vh' }} elevation={10}>
                <h3 style={{ textAlign: 'center' }}>Payment Disclosure</h3>
                <hr />
                <strong>**Credit Card Disclaimer:**</strong>
                <p>By using this credit card, you acknowledge and agree to be subject to a $35 non-sufficient funds (NSF) or chargeback fee for any disputed charges.</p>
                <p>Should you have any billing questions or concerns regarding this payment and/or the amount debited from your account, please contact us at:</p>
                <p>Email: payments@interactiveus.com</p>
                <p>Phone: 225-308-2800</p>
            </Paper>
        </Box>
    )
}

export default Disclosure;