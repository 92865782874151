import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Box, AppBar, Grid, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { ThemeModeContext } from '../../App';
import { EntityContext } from '../../context/EntityContext';
import CurrentTime from './CurrentTime';
import OuachitaImage from '../../images/OuachitaSheriffsOfficePNG.png';
import LafayetteImage from '../../images/LafayetteSheriffsOfficePNG.png';
import WestFelicianaImage from '../../images/WestFelicianaSheriffsOfficePNG.png';
import DesotoImage from '../../images/DesotoSheriffsOfficePNG.png';
import GrantImage from '../../images/GrantSheriffsOfficePNG.png';

const NavMenu = () => {
    const { mode, toggleMode } = useContext(ThemeModeContext);
    const { entity, setAcceptedTerms } = useContext(EntityContext);

    const location = useLocation();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    const [selectedParish, setSelectedParish] = useState(null);

    const parishes = [
        {
            name: 'Ouachita',
            fullName: "Ouachita Parish Sheriff's Office",
            image: OuachitaImage
        },
        {
            name: 'West Feliciana',
            fullName: "West Feliciana Parish Sheriff's Office",
            image: WestFelicianaImage
        },
        {
            name: 'Lafayette',
            fullName: "Lafayette Parish Sheriff's Office",
            image: LafayetteImage
        },
        {
            name: 'Desoto',
            fullName: "Desoto Parish Sheriff's Office",
            image: DesotoImage
        },
        {
            name: 'Grant',
            fullName: "Grant Parish Sheriff's Office",
            image: GrantImage
        },
    ];

    useEffect(() => {
        const foundParish = parishes.find(parish => parish.fullName === entity.entityName);
        if (foundParish) {
            setSelectedParish(foundParish);
        }
    }, [entity]);

    return (
        isSmallScreen ?
            <AppBar position="sticky" sx={{ backgroundColor: 'steelblue', width: 'auto' }}>
                <Toolbar sx={{ px: 'vw' }}>
                    <Grid container alignItems="center"justifyContent="space-around" sx={{mb: '.5vh'} }>
                        {/* Left side */}
                        <Grid item xs={7} md={3}>
                            {/* Left side */}

                            {
                                location.pathname === '/' || !selectedParish
                                    ? ''
                                    : <img src={selectedParish.image} alt={selectedParish.name} style={{
                                        width: '75%',
                                        [theme.breakpoints.down('sm')]: {
                                            width: '60%',
                                        },

                                    }} />
                            }

                        </Grid>
                        <Grid item xs={5} md={3}>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                {/*<Typography variant="h6" component="div" sx={{ width: '20vw' }}>*/}
                                <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }} onClick={() => setAcceptedTerms(false)}>
                                    {
                                        location.pathname === '/'
                                            ? 'Secure Fines'
                                            : <span style={{ width: '100vw', fontSize: '1.3rem', height: '100vh' }}>Secure Fines -<br />{entity.entityName}</span>
                                    }
                                </Link>
                                {/*</Typography>*/}
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            :
            <AppBar position="sticky" sx={{ backgroundColor: 'steelblue', position: 'relative' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Left side */}
                    <Box sx={{ width: '25%' }}>
                        {
                            location.pathname === '/' || !selectedParish
                                ? ''
                                : <div style={{ position: 'absolute', left: '1rem', top: '7vh' }}> {/* Adjust positioning as needed */}
                                    <img src={selectedParish.image} alt={selectedParish.name} style={{ width: '15rem' }} />
                                </div>
                        }
                    </Box>

                    {/* Center */}
                    <Box sx={{ width: '50%', textAlign: 'center' }}> {/* Adjust width and add textAlign */}
                        <Typography variant="h5" component="div">
                            <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }} onClick={() => setAcceptedTerms(false)}>
                                {
                                    location.pathname === '/'
                                        ? 'Secure Fines'
                                        : `Secure Fines - ${entity.entityName}`
                                }
                            </Link>
                        </Typography>
                    </Box>

                    {/* Right side */}
                    <Box sx={{ width: '25%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}> {/* Change to column direction */}
                            {/* Clock component */}
                            <Box>
                                <CurrentTime />
                            </Box>
                            {/* Mode toggle */}
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.5rem', cursor: 'pointer' }} onClick={toggleMode}> {/* Add marginTop */}
                                <Box>
                                    {mode === 'dark' ? <Box sx={{ mb: '1vh' }}><Brightness4Icon /></Box> : <Box sx={{ mb: '1vh' }}><Brightness7Icon /></Box>}
                                </Box>
                                <Box>
                                    <h5> {mode} mode</h5>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
    );
};

export default NavMenu;