import React, { useContext } from 'react';

import { Box, List, ListItem, Typography, ListItemText, Paper } from '@mui/material';

import { EntityContext } from '../../../../context/EntityContext';
import { hideCreditCardNumber } from '../../../../utils';

const PaymentReview = (props) => {
    const { entity } = useContext(EntityContext);

    return (
        <Box sx={{ p: '1vh', pt: 0 }}>
            <Paper elevation={10}>
                <Box sx={{ p: '1vh', mb: '1vh' }}>
                    <h1 style={{ fontSize: '1.5rem', margin: '0' }}>Payment Summary</h1>
                    <p style={{ fontSize: '1rem', color: 'gray', margin: 0 }}>Enter your credit card information</p>
                </Box>
                <Box sx={{ p: '1vh' }}>
                    <List disablePadding>
                        {props.paymentsArray.map((payment) => (
                            <ListItem key={payment.pkViolationId} sx={{ py: 1, px: 0 }}>
                                <ListItemText primary={payment.description} secondary={payment.currentStatute} />
                                <Typography variant="body2">{`$${parseFloat(payment.payment).toFixed(2)}`}</Typography>
                            </ListItem>
                        ))}
                        <ListItem sx={{ py: 1, px: 0 }}>
                            <ListItemText primary="Sub-Total" />
                            <Typography variant="body2">{`$${props.totalPayments}`}</Typography>
                        </ListItem>
                        <ListItem sx={{ py: 1, px: 0 }}>
                            <ListItemText primary="Card fee amount" secondary={`${parseFloat(entity.convenienceFeeRate * 100).toFixed(2)} %`} />
                            <Typography variant="body2">{`$${parseFloat(props.fee).toFixed(2)}`}</Typography>
                        </ListItem>
                        <ListItem sx={{ py: 1, px: 0 }}>
                            <ListItemText primary="Grand Total" />
                            <Typography variant="body2">{`$${props.paymentAmount}`}</Typography>
                        </ListItem>
                    </List>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: props.isSmallScreen ? 'column' : 'row' }}>
                        <Box>
                            <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
                                Defendant
                            </Typography>
                            <Typography>
                                Name: {props.defendantName}
                            </Typography>
                            <Typography>Email: {props.email}</Typography>
                            <Typography>
                                {props.displayPhoneNumber ? <span>Phone Number: {props.displayPhoneNumber}</span> : null}
                            </Typography>
                        </Box>
                        <Box mr={1}>
                            <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
                                Payment details
                            </Typography>
                            <Box>
                                <Typography>Card holder: {props.displayName}</Typography>

                                <Typography>Card number: {props?.creditCardNumber && hideCreditCardNumber(props?.creditCardNumber)}</Typography>

                                <Typography>Expiration Date: {`${props.month}/${props.year}`}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

export default PaymentReview;