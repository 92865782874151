import React, { createContext, useState } from 'react';
import axios from 'axios';

import { apiRoute } from '../App.js';

const EntityContext = createContext({
    loading: false,
    setLoading: () => { },
    entity: {},
    setEntity: () => { },
    getEntity: () => { },
    acceptedTerms: Boolean,
    setAcceptedTerms: () => { },
})

const EntityProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [entity, setEntity] = useState({});
    const [acceptedTerms, setAcceptedTerms] = useState(Boolean);

    const getEntity = (name) => {
        setLoading(true);
        axios.get(`${apiRoute}/api/entity/byName/${name}`)
            .then((res) => setEntity(res.data))
            .catch((err) =>console.log("error getting entity", err))
            .finally(() => setLoading(false))
    }

    const contextValue = {
        loading: loading,
        setLoading,
        entity: entity,
        setEntity,
        getEntity,
        acceptedTerms: acceptedTerms,
        setAcceptedTerms
    }

    return (
        <EntityContext.Provider value={contextValue}>
            {children}
        </EntityContext.Provider>
    )
}

export default EntityProvider;
export { EntityContext };