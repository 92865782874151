import React from 'react';

import { Box, Paper, TextField } from '@mui/material';

import { cleanPhoneNumber, formatPhoneNumber } from '../../../../utils';

const ContactInfo = (props) => {
    const {
        state,
        handleFieldChange,
        handleBlur,
        displayPhoneNumber,
        setDisplayPhoneNumber,
        ts,
        isSmallScreen
    } = props;

    return (
        <Box sx={{ p: '1vh', pt: 0 }}>
            <Paper elevation={10}>
                <Box sx={{ p: '1vh', mb: '1vh' }}>
                    <h1 style={{ fontSize: '1.5rem', margin: '0' }}>Contact Information</h1>
                    <p style={{ fontSize: '1rem', color: 'gray', margin: 0 }}>Enter your contact details</p>
                </Box>
                {isSmallScreen ? <Box sx={{ width: '100%', p: '1vh' }}>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            sx={ts}
                            name='email'
                            id='email'
                            label='Email'
                            value={state?.email}
                            onChange={(e) => handleFieldChange('email', e.target.value)}
                            onBlur={(e) => handleBlur('email', e.target.value)}
                            helperText={state?.errors?.email}
                            error={!!state?.errors?.email}
                            variant='outlined'
                            InputProps={{ shrink: false }}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        sx={ts}
                        name='phoneNumber'
                        id='phoneNumber'
                        label='Phone Number'
                        value={displayPhoneNumber}
                        onChange={(e) => {
                            const newPhoneNumber = formatPhoneNumber(e.target.value);
                            setDisplayPhoneNumber(newPhoneNumber);
                            handleFieldChange('phoneNumber', cleanPhoneNumber(newPhoneNumber));
                        }}
                        onBlur={(e) => { handleBlur('phoneNumber', cleanPhoneNumber(e.target.value)); }}
                        helperText={state?.errors?.phoneNumber}
                        error={!!state?.errors?.phoneNumber}
                        variant='outlined'
                        inputProps={{ maxLength: '14' }}
                    />
                </Box> : <Box sx={{ width: '60%', p: '1vh' }}>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            sx={ts}
                            name='email'
                            id='email'
                            label='Email'
                            value={state?.email}
                            onChange={(e) => handleFieldChange('email', e.target.value)}
                            onBlur={(e) => handleBlur('email', e.target.value)}
                            helperText={state?.errors?.email}
                            error={!!state?.errors?.email}
                            variant='outlined'
                            InputProps={{ shrink: false }}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        sx={ts}
                        name='phoneNumber'
                        id='phoneNumber'
                        label='Phone Number'
                        value={displayPhoneNumber}
                        onChange={(e) => {
                            const newPhoneNumber = formatPhoneNumber(e.target.value);
                            setDisplayPhoneNumber(newPhoneNumber);
                            handleFieldChange('phoneNumber', cleanPhoneNumber(newPhoneNumber));
                        }}
                        onBlur={(e) => { handleBlur('phoneNumber', cleanPhoneNumber(e.target.value)); }}
                        helperText={state?.errors?.phoneNumber}
                        error={!!state?.errors?.phoneNumber}
                        variant='outlined'
                        inputProps={{ maxLength: '14' }}
                    />
                </Box>}
            </Paper>
        </Box>
    )
}

export default ContactInfo;