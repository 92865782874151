import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { Box, Card, Button, TextField, CardHeader, CardActions, CardContent, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { apiRoute } from '../../App';
import { EntityContext } from '../../context/EntityContext';
import TermsModal from '../modals/TermsModal';

const SearchForm = (props) => {
    const { setSearchResults, setLoadingSearch, setFormSubmitted, state, dispatch, ts, setCaseId } = props;

    const { entity, acceptedTerms, setAcceptedTerms } = useContext(EntityContext);

    const [modal, setModal] = useState(acceptedTerms);

    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    const sessionData = sessionStorage.getItem(window.location.hostname);
    let isAgreed = false;

    if (sessionData) {
        const { value, expires } = JSON.parse(sessionData);
        if (expires > new Date().getTime()) {
            setAcceptedTerms(value);
        } else {
            // If session has expired, remove it from session storage
            sessionStorage.removeItem(window.location.hostname);
            setAcceptedTerms(false);
            navigate('/');
        }
    };

    //// Update the modal state based on the session storage value
    //useEffect(() => {
    //    setModal(!isAgreed);
    //}, [isAgreed]);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            default:
                if (!value && field !== "driverLicenseNumber") {
                    error = `Required!`;
                }
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSearchResults([]);
        const searchParams = new URLSearchParams();
        if (state.caseCitationNumber) searchParams.set('caseCitationNumber', state.caseCitationNumber)
        if (state.firstName) searchParams.set('firstName', state.firstName)
        if (state.lastName) searchParams.set('lastName', state.lastName)
        if (state.dateOfBirth) searchParams.set('dateOfBirth', state.dateOfBirth)
        if (state.driverLicenseNumber) searchParams.set('driverLicenseNumber', state.driverLicenseNumber)
        if (entity.id) searchParams.set('entityId', entity.id)

        setFormSubmitted(true);
        setLoadingSearch(true);
        axios.get(`${apiRoute}/api/Search?${searchParams.toString()}`)
            .then(res => {
                setSearchResults(res.data.map((item) => ({
                    ...item,
                    id: item.pkViolationId
                })));
                setCaseId(res.data[0].caseId);
            })
            .catch(err => console.log(err))
            .finally(() => setLoadingSearch(false))
    };

    const handleReset = () => {
        handleFieldChange('caseCitationNumber', '');
        handleFieldChange('firstName', '');
        handleFieldChange('lastName', '');
        handleFieldChange('dateOfBirth', '');
        handleFieldChange('driverLicenseNumber', '');
    };

    const handleTermsAgreement = () => {
        const expirationTime = new Date().getTime() + 3600000; // 1 hour in milliseconds
        sessionStorage.setItem(window.location.hostname, JSON.stringify({ value: true, expires: expirationTime }));
        setAcceptedTerms(true);
        setModal(false);
    };

    const handleDecline = () => {
        navigate('/');
        setModal(false);
        setAcceptedTerms(false);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Card sx={{ width: isSmallScreen ? '99%' : '55%', m: '.5vh auto' }} elevation={5}>
                    <CardHeader title="Search" subheader="Enter citation or case info" />
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : null, gap: '1rem' }}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="caseCitationNumber"
                                label="Case/Citation Number*"
                                value={state?.caseCitationNumber}
                                onChange={(e) => handleFieldChange('caseCitationNumber', e.target.value)}
                                onBlur={(e) => handleBlur('caseCitationNumber', e.target.value)}
                                helperText={state?.errors?.caseCitationNumber}
                                error={!!state?.errors?.caseCitationNumber}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                sx={ts}
                                id="firstName"
                                label="First Name*"
                                value={state?.firstName}
                                onChange={(e) => handleFieldChange('firstName', e.target.value)}
                                onBlur={(e) => handleBlur('firstName', e.target.value)}
                                helperText={state?.errors?.firstName}
                                error={!!state?.errors?.firstName}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                sx={ts}
                                id="lastName"
                                label="Last Name*"
                                value={state?.lastName}
                                onChange={(e) => handleFieldChange('lastName', e.target.value)}
                                onBlur={(e) => handleBlur('lastName', e.target.value)}
                                helperText={state?.errors?.lastName}
                                error={!!state?.errors?.lastName}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : null, gap: '1rem', mt: '2vh' }}>
                            <TextField
                                fullWidth
                                sx={ts}
                                id="dateOfBirth"
                                label="Date of Birth*"
                                type="date"
                                value={state?.dateOfBirth}
                                onChange={(e) => handleFieldChange('dateOfBirth', e.target.value)}
                                onBlur={(e) => handleBlur('dateOfBirth', e.target.value)}
                                helperText={state?.errors?.dateOfBirth}
                                error={!!state?.errors?.dateOfBirth}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                sx={ts}
                                id="driverLicenseNumber"
                                label="Driver License Number"
                                value={state?.driverLicenseNumber}
                                onChange={(e) => handleFieldChange('driverLicenseNumber', e.target.value)}
                                onBlur={(e) => handleBlur('driverLicenseNumber', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                            />
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Box sx={{ display: 'flex', gap: '1rem', flexDirection: isSmallScreen ? 'column' : null, width: isSmallScreen ? '98%' : null, ml: isSmallScreen ? '1vw' : null, mt: isSmallScreen ? '-.5vh' : null, mb: isSmallScreen ? '.2vh' : null}}>
                            <Button variant="contained" sx={{ backgroundColor: 'indianred', color: 'white', height: isSmallScreen ? '7.5vh' : 'inherit' }} onClick={handleReset}>Clear Fields</Button>
                            <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', height: isSmallScreen ? '7.5vh' : 'inherit' }} type="submit">Search</Button>
                        </Box>
                    </CardActions>
                </Card>
            </form>
            <Modal open={!acceptedTerms} sx={{ width: '75%', m: '5vh auto' }}>
                <>
                    <TermsModal
                        handleSubmit={handleTermsAgreement}
                        handleDecline={handleDecline}
                    />
                </>
            </Modal>
        </>
    )
};

export default SearchForm;